let $mobile = false
const options_text = $('.orderby option')
let original_options_text = []
function change_filter_text() {
    if($(window).width() < 851) {
        $mobile = true
        options_text.each( function() {
            const $this_text = $(this).text()
            const $this = $(this)
            original_options_text.push($this_text)
            if ($this_text.includes('Sort') || $this_text.includes('by') || $this_text.includes('price') || $this_text.includes(':')) {
                const new_text = $this_text.replace('Sort', '').replace('by', '').replace('price', '').replace(':', '');
                $this.text(new_text)
            }
        })
    } else {
        if($mobile === true) {
            $mobile = false
            $('.filters-container .filter-button-text').text('Select Filters')
            options_text.each( function(index) {
                const $this = $(this)
                $this.text(original_options_text[index])
            })
        }
    }
}

change_filter_text()
$(window).on('resize', () => {
    if($('.woocommerce-ordering')) {
        change_filter_text()
    }
})
