import $ from 'jquery'
const $request_quote = $('.wc_quote_single_page.button')
const $product_expand_icon = $('.product-gallery-expand')

if($('.single-product').length !== 0) {
    const $productName = $('.summary .product_title').text()
    localStorage.setItem("productName", $productName)
}

if($('.section-rental-form').length !== 0) {
    $(".product-name input").val(localStorage.getItem("productName"))
}

$request_quote.on('click', function () {
    $(document).ajaxComplete(() => {
        $request_quote.hide()
    })
})

if ($product_expand_icon.length !== 0) {
    $product_expand_icon.on('click', function() {
        if($(".flex-active-slide a").length !== 0) {
            $(".flex-active-slide a").click();
        } else {
            $('.woocommerce-product-gallery__image a').click()
        }
    });
}

$(document).ready(function() {
    const $product_cta = $('.product-cta-container')
    const $flex_viewport = $('.flex-viewport')

    if ($flex_viewport.length === 0) {
        $product_cta.css({'width': 'calc(100% - 20px)', "bottom": '10px'})
    }

    if($product_cta.length !== 0) {
        $('.woocommerce-product-gallery__wrapper a img').addClass('cta-margin')
    }

})

$(document).ajaxComplete(function() {
    if ($('.single-product').length !== 0 ) {
        const $request_quote = $('.wc_quote_single_page')
        if($request_quote.length !== 0) {
            $request_quote.remove()
                $('.add-to-cart-wrapper').each(function() {
                    const $this = $(this)
                    let originalButton = $this.find('> a');
                    if(originalButton.length === 0) {
                        originalButton = $this.find('> button');
                    }
                    const clonedButton = originalButton.clone();
                    clonedButton.css({
                        backgroundColor: '#E6B521',
                        color: '#252422',
                    });
                    clonedButton.css({
                        width: "100%",
                        height: "100%",
                        position: 'absolute',
                        opacity: '0',
                        bottom: 0,
                        left: 0,
                        zIndex: 1,
                        borderTop: 'none',
                        transform: "translateY(100%)",
                    })
                    clonedButton.addClass('clonedButton')
                        $this.append(clonedButton);
                });
        }
    }
})



