import $ from 'jquery'
let $textWidth = $('#loop').width()
let scrollDistance = (-$textWidth) + ($textWidth / 8);
let outer = $('#outer');
let loop = outer.find('#loop');

$(window).on('scroll', () => {
    loop.css('transform', 'translateX(' + scrollDistance + 'px)');
    scrollDistance += 2;
});
