import Swiper from 'swiper';
import lightGallery from "lightgallery";

const create_lightbox = (container, swiper_slide) => {
    const swiperContainers = document.querySelector('.' + container);
    const gsBgImgSelector = '.' + swiper_slide + " img";

    const dynamicEl = [...swiperContainers.querySelectorAll(gsBgImgSelector)].map(
        (sliderImg) => {
            return {
                src: sliderImg.src,
                thumb: sliderImg.src,
                subHtml: sliderImg.src,
            };
        }
    );
    const dynamicGallery = swiperContainers
    const popup = lightGallery(dynamicGallery, {
        dynamic: true,
        loop: true,
        autoplayVideoOnSlide: true,
        dynamicEl,
    });
    dynamicGallery.addEventListener('click', () => {
        popup.openGallery(0);
    });
    [...swiperContainers.querySelectorAll('.swiper-slide')].map((slide, idx) => {
        slide.addEventListener('click', () => {
            popup.openGallery(idx);
        });
    });
}

$(document).ready(function () {
    $(".swiper-projects").each(function (index, element) {
        const swiper = new Swiper('.swiper-projects-' + index, {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 30,
            navigation: {
                nextEl: '.projects-swiper-button-next-' + index ,
                prevEl: '.projects-swiper-button-prev-' + index
            },
            pagination: {
                el: ".projects-pagination-" + index,
                type: "custom",
                renderCustom: function (swiper, current, total) {
                    // Function to render custom pagination (with leading zeros)
                    return ("00" + current).slice(-2) + " / " + ("00" + total).slice(-2);
                }
            }
        });
        create_lightbox("swiper-projects-" + index, "swiper-slide-" + index)
    });
});

$('.recent-projects-container_cards .card').each(function (index, element) {
    if($(element).find('.swiper-slide').length === 1) {
        $(element).addClass('remove-arrows')
    }
})

const cta_swiper = new Swiper(".cta-swiper", {
    speed: 800,
    navigation: {
        nextEl: ".cta-next",
        prevEl: ".cta-prev",
    },
});

const brandsSlider  = new Swiper('.brands-slider',{
    direction: 'horizontal',
    slidesPerView: 1,
    autoHeight: false,
    loop: true,
    spaceBetween: 75,
    navigation:{
        nextEl: ".brands-swiper-button-next",
        prevEl: ".brands-swiper-button-prev",
    },
    autoplay: {
        delay: 4000,
    },
    breakpoints: {
        200: {
            spaceBetween: 30,
            slidesPerView: 3,
        },
        501: {
            slidesPerView: 2,
        },
        700: {
            slidesPerView: 3,
        },
        1300: {
            slidesPerView: 5,
        },
    }
})

const category_accessories  = new Swiper('.category-accessories-slider',{
    slidesPerView: 1,
    loop: true,
    spaceBetween: 30,
    navigation:{
        nextEl: ".brands-swiper-button-next",
        prevEl: ".brands-swiper-button-prev",
    },
    breakpoints: {
        200: {
            slidesPerView: 1,
        },
        600: {
            slidesPerView: 2,
        },
        1100: {
            slidesPerView: 3,
        },
        1530: {
            slidesPerView: 4,
        },
    }
})

const top_products_swiper = new Swiper(".swiper-top-products", {
    slidesPerView: 4,
    direction: "vertical",
    navigation: {
        nextEl: ".swiper-button-next-top",
        prevEl: ".swiper-button-prev-top",
    },
    breakpoints: {
        200: {
            direction: 'horizontal',
            slidesPerView: 1.5,
            spaceBetween: 2,
            allowTouchMove: true,
        },
        360: {
            direction: 'horizontal',
            slidesPerView: 2,
            spaceBetween: 2,
        },
        600: {
            direction: 'horizontal',
            slidesPerView: 2,
            spaceBetween: 2,
        },
        1200: {
            allowTouchMove: false,
            direction: "vertical"
        }
    }
});


const you_may_like_swiper = new Swiper(".swiper-you-may-like", {
  spaceBetween: 20,
  freeMode: true,
  navigation: {
    nextEl: ".swiper-button-next-you-may-like",
    prevEl: ".swiper-button-prev-you-may-like",
  },
  breakpoints: {
    1600: {
      slidesPerView: 4,
    },
    1330: {
      slidesPerView: 3,
    },
    600: {
      direction: 'horizontal',
      slidesPerView: 2,
      spaceBetween: 2,
    },
    360: {
      direction: 'horizontal',
      slidesPerView: 2,
      spaceBetween: 2,
    },
    200: {
      direction: 'horizontal',
      slidesPerView: 1.5,
      spaceBetween: 2,
      allowTouchMove: true,
    },
  }
});

const recently_viewed_swiper = new Swiper(".recently-viewed-swiper", {
    spaceBetween: 20,
    freeMode: true,
    navigation: {
        nextEl: ".swiper-button-next-recent",
        prevEl: ".swiper-button-prev-recent",
    },
    breakpoints: {
        1600: {
            slidesPerView: 4,
        },
        1330: {
            slidesPerView: 3,
        },
        600: {
            direction: 'horizontal',
            slidesPerView: 2,
            spaceBetween: 2,
        },
        360: {
            direction: 'horizontal',
            slidesPerView: 2,
            spaceBetween: 2,
        },
        200: {
            direction: 'horizontal',
            slidesPerView: 1.5,
            spaceBetween: 2,
            allowTouchMove: true,
        },
    }
});

const serviceSwiper = new Swiper('.swiper-service', {
    loop: false,
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
        1200: {
            slidesPerView: 3
        },
        700: {
            slidesPerView: 2,
        },
        300: {
            slidesPerView: 1
        }
    },
    navigation: {
        nextEl: '.service-swiper-button-next',
        prevEl: '.service-swiper-button-prev',
    },
    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
    },
});

$('.card_expand').on('click', (e) => {
    const $this = $(e.currentTarget)
    const $parent_container = $this.parent()
    $parent_container.find('.swiper-slide-active img').click()
})
